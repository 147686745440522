import { ErrorPage } from "@screens/error"
import { NextPage } from "next"
import React from "react"

interface Props {
  statusCode: number | null | undefined
}

const Error: NextPage<Props> = ({ statusCode }) => (
  <ErrorPage statusCode={statusCode} />
)

Error.getInitialProps = async ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null
  return { statusCode }
}

export default Error
